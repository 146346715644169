<template>
  <div>
    <div>
      <b-button-toolbar justify class="toolbar-top-margin">
        <b-button-group></b-button-group>
        <b-button-group class="mr-2 mb-1" vertical>
          <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="outline-primary" @click="refresh">
            <b-spinner v-if="getLoading" small style="margin-bottom: 3px"></b-spinner>
            Refresh
          </b-button>
        </b-button-group>
      </b-button-toolbar>
    </div>
    <b-overlay :show="getLoading" rounded="sm" opacity="0.8">
      <b-row>
        <b-col lg="4">
          <b-card>
            <h3 class="mb-0 text-center">Total Patients</h3>
            <br />
            <b-card-text class="text-center"
              ><h3>{{ totalPatients }}</h3></b-card-text
            >
          </b-card>
        </b-col>
        <b-col lg="4">
          <b-card>
            <h3 class="mb-0 text-center">Verified Patients</h3>
            <br />
            <b-card-text class="text-center"
              ><h3>{{ validatedPatients }}</h3></b-card-text
            >
          </b-card>
        </b-col>
        <b-col lg="4">
          <b-card>
            <h3 class="mb-0 text-center">Incomplete Patient Data</h3>
            <br />
            <b-card-text class="text-center"
              ><h3>{{ incomplete }}</h3></b-card-text
            >
          </b-card>
        </b-col>
      </b-row>
      <h3>Donation Listings: {{ totalDListings }}</h3>

      <div class="accordion" role="tablist">
        <b-card v-for="(d, index) of donations" :key="d.id">
          <b-card-header header-tag="header" class="p-1" role="tab">
            <b-button block v-b-toggle="'donation-a-' + index" variant="info">
              <h3 class="mb-0">{{ d.name }}</h3>
            </b-button>
          </b-card-header>
          <b-collapse :id="'donation-a-' + index" :accordion="'donation-accordion-' + index" :visible="index === 0" role="tabpanel">
            <b-row no-gutters>
              <b-col md="6">
                <b-card>
                  <b-img v-bind:src="d.image.urlTiny" alt="Image" class="rounded-0" />
                </b-card>
              </b-col>
              <b-col md="6">
                <b-card-body title="Listing Details">
                  <b-card>
                    <b-card-text> {{ d.description }} </b-card-text>
                    <br />
                    <b-card-text> <b>Quantity:</b> {{ d.residualQuantity }} {{ d.quantityType }} </b-card-text>
                    <b-card-text> <b>Expiry date:</b> {{ d.inventoryItem.expirationDate }} </b-card-text>
                    <b-card-text> <b>Packaged date:</b> {{ d.inventoryItem.packagedDate }} </b-card-text>
                    <b-card-text> <b>Category:</b> {{ d.inventoryItem.productCategoryName }} </b-card-text>
                    <b-card-text> <b>Tag:</b> {{ d.inventoryItem.label }} </b-card-text>
                    <b-card-text>
                      <b>Listing Facility:</b> {{ d.inventoryItem.facilityName }} <br />
                      <b>License:</b> {{ d.inventoryItem.facilityLicenseNumber }}
                    </b-card-text>
                    <br />
                    <b-card-text>
                      <a :href="'https://app.getlisto.dev/redirect/view/listing/' + d.listingUid + '?session=listing'" target="_blank"> View in Listo </a>
                    </b-card-text>
                  </b-card>
                </b-card-body>
              </b-col>
            </b-row>
          </b-collapse>
        </b-card>
      </div>
    </b-overlay>
  </div>
</template>

<script>
import {
  BLink,
  BCardText,
  BCardBody,
  BCollapse,
  BCardHeader,
  BCardImg,
  BRow,
  BCol,
  BCard,
  BTable,
  BButtonGroup,
  BButtonToolbar,
  BSpinner,
  BOverlay,
  BIcon,
  VBToggle,
  BImg
} from "bootstrap-vue";
import { BButton } from "bootstrap-vue";
import { onLoadDashboard, getLoading } from "./dashboardUtils";
import { onMounted, ref, watch } from "@vue/composition-api";
import Ripple from "vue-ripple-directive";
import { useRouter } from "@core/utils/utils";

export default {
  name: "CompassionDashboard",
  components: {
    BLink,
    BCard,
    BImg,
    BCardImg,
    BCardBody,
    BCollapse,
    BCardHeader,
    BCardText,
    BButtonToolbar,
    BButtonGroup,
    BSpinner,
    BOverlay,
    BCol,
    BRow,
    BButton,
    BTable,
    BIcon,
    Ripple
  },
  directives: {
    Ripple,
    "b-toggle": VBToggle
  },
  setup() {
    const { router } = useRouter();
    const donations = ref([]);
    const incomplete = ref(0);
    const totalPatients = ref(0);
    const totalDListings = ref(0);
    const validatedPatients = ref(0);

    const refresh = async () => {
      const { data } = await onLoadDashboard();
      console.log("got it", data);
      donations.value = data.donations;
      incomplete.value = data.incompletePatients;
      totalPatients.value = data.totalPatients;
      totalDListings.value = data.totalDonationListings;
      validatedPatients.value = data.validatedPatients;
    };

    onMounted(() => {
      refresh();
    });

    return {
      getLoading,
      refresh,
      donations,
      incomplete,
      totalPatients,
      totalDListings,
      validatedPatients
    };
  }
};
</script>

<style scoped></style>
