import { ref, computed } from "@vue/composition-api";
import { requestLoadCompassionDashboard } from "@/service/DashboardService";

const loading = ref(false);

export const getLoading = computed(() => loading.value);

export const onLoadDashboard = async () => {
  try {
    loading.value = true;
    return await requestLoadCompassionDashboard();
  } finally {
    loading.value = false;
  }
};
